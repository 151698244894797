




























import Vue from "vue";
import { ViewEmployees } from "@/interfaces/recruiter/employees/view_employees/view_employees";
import ViewEmployeesHead from "@/components/recruiter/employees/view_employees/ViewEmployeesHead.vue";
import ViewEmployeesBody from "@/components/recruiter/employees/view_employees/ViewEmployeesBody.vue";

export default Vue.extend({
  name: "ViewEmployees",
  components: {
    ViewEmployeesBody,
    ViewEmployeesHead
  },
  data(): ViewEmployees {
    return {
      active_tab: 1
    };
  },
  methods: {
    update_employees(tab: number) {
      switch (tab) {
        case 1:
          this.active_tab = 1;
          break;
        case 2:
          this.active_tab = 2;
          break;
      }
    }
  }
});
