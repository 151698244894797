
















import Vue from "vue";

export default Vue.extend({
  name: "ProfilePictureDirect",

  props: {
    avatar_uri: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    // For using .avatar class
    // By default using .user-details-avatar class
    extra_avatar_class: {
      type: Boolean,
      default: false
    },
    mobile_size: {
      type: Number,
      default: 70
    },
    desktop_size: {
      type: Number,
      default: 140
    }
  }
});
