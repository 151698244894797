import { Candidates } from "@/interfaces/data_objects/candidates";

export function fetch_name(name: Candidates.Name): string {
  if (!name) return "";
  return name.first_name
    ? name.first_name + " " + name.middle_name + " " + name.last_name
    : name.text;
}

export function fetch_latest_work_history(
  work: Candidates.WorkHistoryDetailed
): Candidates.WorkHistoryDetails {
  const details = Object.values(work);
  if (details.length <= 0)
    return {
      job_title: "",
      details: "",
      organization: "",
      time_period: ""
    };
  return details[0];
}
